#Thumbnail4{
    margin: 15px;
    height: 240px;
    background-color: white;
    border: 3px solid lightblue;
    box-shadow: 0px 0px 2px 0px black;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    border-radius: 1vmax;
    overflow-y: hidden;
    position: relative;

    .sellerInfo{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .thumbImg{
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    .ImgHolder2{
        height: 95px;
        width: 95px;
        border: 4px solid lightblue;
        border-radius: 50%;
        background-color: rgb(53, 103, 119);
        box-shadow: 0px 0px 2px 0px black;
        position: relative;
        top: 10px;
    }

    .location{
        display: grid;
        grid-template-columns: 20px 1fr;
        min-width: 80%;
        max-width: 100%;
    }

    .pin{
        height: 15px;
        width: 15px;
        position: relative;
        top: 10px;
    }

    .a{
        margin-top: 5px;
        margin-bottom: 5px;
        min-width: 80%;
        max-width: 100%;
        position: relative;
        top: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .a1{
        margin-top: 0px;
        margin-bottom: 5px;
        width: 95%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        top: 10px;
    }

    .text-block,
    .title-block{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .title{
        margin: 0px;
        text-align: center;
        height: fit-content;
        width: 100%;
    }

    .text{
        font-size: 13px;
        word-break: break-word;
        text-align: center;
        margin: 0px;
        max-height: 50px; /* This is to ensure that the text doesnt cause the thumbnail to get stretched */
    }

    .rating{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        height: 95%;
        position: relative;
        top: 2.5%;
    }

    .star{
        height: 30px;
        width: 30px;
    }

    .comment-like{
        display: grid;
        min-width: 70px;
        max-width: 100%;
        grid-template-columns: 1fr 1fr;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .commentTable{
        height: 100%;
        background-color: white;
        box-shadow: 0px 0px 2px 0px black;
        position: absolute;
        left: 40%;
        bottom: 0px;
        transition: 3s ease-in-out;
        z-index: 1;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .commentTable::-webkit-scrollbar{
        background-color: rgba(0,0,0,0.3);
        width: 6px;
    }
    .commentTable::-webkit-scrollbar-thumb{
        background-color: rgba(0,0,0,0.7);
        width: 6px;
        border-radius: 4px;
    }

    .comment{
        height: 20px;
        width: 20px;
        position: relative;
        top: 20px;
    }

    .like{
        height: 20px;
        width: 20px;
        position: relative;
        top: 20px;
    }

    .serviceSpec{
        cursor: pointer;
        display: grid;
        grid-template-rows: 85px 1fr;
    }

    @media (max-width:400px){
        height: 230px;
        .star{
            height: 20px;
            width: 20px;
        }
        .a,
        .a1,
        .text{
            font-size: 12px;
            font-weight: bold;
        }
        .ImgHolder2{
            height: 70px;
            width: 70px;
            border: 2px solid lightblue;
        }
        .title{
            margin-top: 15px;
            font-size: 20px;
        }
        .serviceSpec{
            cursor: pointer;
            display: grid;
            grid-template-rows: 40px 1fr;
        }
    }
    @media (max-width:350px){

        .a,
        .a1,
        text{
            font-size: 9px;
            font-weight: bold;
        }
        height: 180px;
    }

}

