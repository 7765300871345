#CardHandler{
        width: 100dvw;
        height: 100vh;
        background-color: rgba(0,0,0,0.7);
        z-index: 1000;
        position: fixed;
        top: 0px;
        left: 0px;
        display: flex;
        flex-direction: column;
        align-items: center;      
        justify-content: center;  
        overflow-y: scroll;
        overflow-x: hidden;

    .addCard-heading{
        margin: 0px;
        text-align: center;
        margin-top: 15px;
        font-size: 35px;
    }
    .AddCard{
        width: 400px;
        height: 520px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px 0px black;
        background-color: white;
        background-color: rgb(233, 242, 245);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .close-addcard{
        font-size: 20px !important;
        font-weight: bold;
        position: absolute;
        right: 10px;
        top: 10px;
        margin: 0px;
        cursor: pointer;
    }
    .holder{
        height: fit-content;
        width: fit-content;
        margin-top: 30px;
    }
    .card-types{
        height: 80px;
        width: 332px;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 15px;
    }
    .card-types div{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: center;
    }
    .type1,
    .type2{
        margin-top: 10px;
        font-weight: bold;
    }
    .grid-block{
        height: 100px;
        width: 332px;
        display: flex;
        flex-direction: row;
        position: relative;
        margin-top: 0px;
    }
    .gb1{
        position: absolute;
        left: 0px;
    }
    .gb2{
        position: absolute;
        right: 0px;
    }
    .cardHolder,
    .cardNumber{
        position: relative;
        border: solid 1px grey;
        width: 320px;
        height: 40px;
        font-size: 20px;
        padding-top:20px;
        padding-left: 10px;
        border-radius: 0.5vmax;
        background-color: white;
        box-shadow: 0px 0px 5px -2px black;
    }

    .cardExpiry{
        position: relative;
        border: solid 1px grey;
        width: 140px;
        height: 40px;
        padding-top:20px;
        padding-left: 10px;
        border-radius: 0.5vmax;
        background-color: white;
        box-shadow: 0px 0px 5px -2px black;
    }

    .cardCVC{
        position: relative;
        border: solid 1px grey;
        width: 140px;
        height: 40px;
        padding-top:20px;
        padding-left: 10px;
        border-radius: 0.5vmax;
        background-color: white;
        box-shadow: 0px 0px 5px -2px black;
    }

    .card-icon{
        width: 25px;
        height: 17px;
        position: absolute;
        right: 10px;
        top: 22px;
    }

    .cardButton{
        width: 150px;
        height: 30px;
        border-radius: 4vmax;
        position: absolute;
        bottom: 20px;
        box-shadow: 0px 0px 5px -2px black;
        cursor: pointer;
    }

    .holder-input,
    .number-input{
        height: 30px;
        width: 150px;
        margin: 0px;
        position: absolute;
        top: 15px;
        outline: none;
        border: none;
    }
    .expiry-input{
        height: 30px;
        width: 100px;
        margin: 0px;
        position: absolute;
        top: 15px;
        outline: none;
        border: none;
    }
    .cvv-input{
        height: 30px;
        width: 100px;
        margin: 0px;
        position: absolute;
        top: 15px;
        outline: none;
        border: none;
    }

    .invalid-tag{
        position: absolute;
        right: 0px;
        font-size: 12px;
        font-weight: bold;
        color: red;
        top: -35px;
    }
    .label{
        position: relative;
        bottom: 5px;
    }

    .popup-message{
        z-index: 1001;
        height: 100px;
        width: 100px;
        box-shadow: 0px 0px 10px 0px darkblue;
        padding: 5px;
        background-color: white;
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .notification{
        font-size: 15px;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
    .close-popup{
        font-size: 15;
        font-weight: bold;
        position: absolute;
        top: -40px;
        right: -20px;
        cursor: pointer;
    }

    @media (max-width:450px){
        .AddCard{
            width: 100vw;
            min-height: 100vh;
            border-radius: 0vmax;
        }
        
        .addCard-heading{
            font-size: 25px;
        }
        .close-addcard{
            font-size: 20px !important;
            font-weight: bold;
            position: absolute;
            right: 10px;
            top: 10px;
            margin: 0px;
            cursor: pointer;
        }
        .holder{
            height: fit-content;
            width: fit-content;
            margin-top: 30px;
        }
        .card-types{
            width: 80vw;
        }
        .grid-block{
            width: 80vw;
            display: flex;
            flex-direction: row;
            position: relative;
            margin-top: 20px;
        }
        .gb1{
            position: absolute;
            left: 0px;
        }
        .gb2{
            position: absolute;
            right: 0px;
        }
        .cardHolder,
        .cardNumber{
            position: relative;
            border: solid 1px grey;
            width: 80vw;
            height: 40px;
            font-size: 20px;
            padding-top:20px;
            padding-left: 10px;
            border-radius: 0.5vmax;
            background-color: white;
            box-shadow: 0px 0px 5px -2px black;
        }
    
        .cardExpiry{
            position: relative;
            border: solid 1px grey;
            width: 30vw;
            height: 40px;
            padding-top:20px;
            padding-left: 10px;
            border-radius: 0.5vmax;
            background-color: white;
            box-shadow: 0px 0px 5px -2px black;
        }
    
        .cardCVC{
            position: relative;
            border: solid 1px grey;
            width: 30vw;
            height: 40px;
            padding-top:20px;
            padding-left: 10px;
            border-radius: 0.5vmax;
            background-color: white;
            box-shadow: 0px 0px 5px -2px black;
        }
    
        .card-icon{
            width: 25px;
            height: 17px;
            position: absolute;
            right: 10px;
            top: 22px;
        }
    
        .cardButton{
            width: 150px;
            height: 30px;
            border-radius: 4vmax;
            position: absolute;
            bottom: 20px;
            box-shadow: 0px 0px 5px -2px black;
            cursor: pointer;
        }
    
        .number-input{
            height: 30px;
            width: 60vw;
            margin: 0px;
            position: absolute;
            top: 15px;
            outline: none;
            border: none;
        }
        .expiry-input{
            height: 30px;
            width: 22vw;
            margin: 0px;
            position: absolute;
            top: 15px;
            outline: none;
            border: none;
        }
        .cvv-input{
            height: 30px;
            width: 22vw;
            margin: 0px;
            position: absolute;
            top: 15px;
            outline: none;
            border: none;
        }
    
        .invalid-tag{
            position: absolute;
            right: 0px;
            font-size: 12px;
            font-weight: bold;
            color: red;
            top: -35px;
        }
        .label{
            font-size: 12px;
            font-weight: bold;
        }
        input{
            font-size: 10px;
            font-weight: bold;
        }
    }
    @media (max-width:300px){
        .addCard-heading{
            font-size: 20px;
        }
        .close-addcard{
            font-size: 15px !important;
        }
    }
}

#CardHandler::-webkit-scrollbar{
    width: 10px;
    background-color: rgba(255,255,255, 0.3);
}
#CardHandler::-webkit-scrollbar-thumb{
    width: 10px;
    background-color: rgba(255,255,255, 0.7);
    border-radius: 4vmax;
}

@media (max-width:450px){
    #CardHandler::-webkit-scrollbar{
        width: 0px;
    }
    #CardHandler::-webkit-scrollbar-thumb{
        width: 0px;
    }
}