#BookingPage{
    margin-top: 80px;
    width: 100dvw;
    min-height: 533.33px;
    position: relative;
    background-color: rgba(173,216,230,0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    .progress-block{
        height: 100px;
        width: 638px;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        position: relative;
        align-items: center;
    }
    .progress-labels{
        width: 733px;
        height: 45px;
        display: flex;
        flex-direction: row;
        position: relative;
    }

    .label1,
    .label2,
    .label3,
    .label4{
        margin-top: 3px;
        width: 150px;
        position: absolute;
        text-align: center;
        font-weight: bold;
    }
    .label1{
        left: 15px;
    }
    .label2{
        left: 200px;
    }
    .label3{
        left: 380px;
    }
    .label4{
        right: 15px;
    }
    .data-block{
        height: 405px;
        width: 1026px;
        border: solid 4px lightblue;
        border-radius: 2vmax;
        display: grid;
        grid-template-columns: 1fr 1fr;
        background-color: white;
        overflow: hidden;
        margin-bottom: 30px;
    }
    .circle1,
    .circle2,
    .circle3,
    .circle4{
        width: 90px;
        height: 90px;
        border-radius: 100%;
        background-color: lightblue;
        position: relative;
    }

    .connector1,
    .connector2,
    .connector3{
        height: 35px;
        width: 100px;
        background-color: lightblue;
        position: relative;
        z-index: 10;
    }

    .connector1{
        left: -4px;
    }
    .connector2{
        left: -12px;
    }
    .connector3{
        left: -20px;
    }
    
    .circle2{
        left: -8px;
    }
    .circle3{
        left: -16px;
    }
    .circle4{
        left: -24px;
    }

    .circle-filler,
    .inner-circle{
        width: 70px;
        height: 70px;
        border-radius: 100%;
    }

    .inner-circle{
        background-color: white;
        margin: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
    }
    .circle-filler{
        background-color: rgba(173, 216, 230, 0.5);
        position: absolute;
    }

    .connector-filler,
    .inner-connector{
        height: 15px;
        width: 117px;
    }

    .inner-connector{
        background-color: white;
        margin-top: 10px;
        position: relative;
        left: -9px;
    }
    .connector-filler{
        background-color: rgba(173, 216, 230, 0.5);
        position: absolute;
    }

    .inner-circle p{
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }

    .right-block{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 2fr 1fr; 
        position: relative;
        justify-items: center;
    }
    .left-block{
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-rows: 2fr 1.5fr;
    }
    .buttons-block,
    .profile-block{
        width: 100%;
        height: 100%;
        position: relative;
    }
    .buttons-block{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .booking-block{
        width: 80%;
        height: 200px;
        margin-left:10%;
        margin-top: 10px;
        border-bottom: solid 2px gray;
        border-top: solid 2px gray;
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    .booking-block::-webkit-scrollbar{
        background-color: rgba(0,0,0,0.3);
        width: 7px;
    }
    .booking-block::-webkit-scrollbar-thumb{
        background-color: rgba(0,0,0,0.9);
        border-radius: 4vmax;
    }
    .frame{
        height: 150px;
        width: 150px;
        overflow: hidden;
        border: 5px solid lightblue;
        border-radius: 100%;
        margin: 20px;
        background-color: rgb(53, 103, 119);
        box-shadow: 0px 0px 2px 0px black;
    }
    .frame img{
        height: 100%;
        width: 100%;
        object-fit: contain;
        border-radius: 100%;
    }
    .booking-name{
        position: absolute;
        top: 75px;
        left: 225px;
        width: 150px;
        white-space: nowrap;          
        overflow: hidden;             
        text-overflow: ellipsis;
        font-size: 13px;
        font-weight: bold;
    }
    .booking-surname{
        position: absolute;
        top: 100px;
        left: 225px;
        width: 150px;
        white-space: nowrap;          
        overflow: hidden;             
        text-overflow: ellipsis;
        font-size: 13px;
        font-weight: bold;
    }
    .booking-rating{
        position: absolute;
        top: 75px;
        left: 350px;
        width:fit-content;
        font-size: 13px;
        font-weight: bold;
    }
    .booking-jobs{
        position: absolute;
        top: 100px;
        left: 350px;
        width: fit-content;
        font-size: 13px;
        font-weight: bold;
    }
    .booking-statement{
        position: absolute;
        top: 35px;
        left: 225px;
        width: fit-content;
        text-align: center;
        font-size: 17px;
        font-weight: bold;
    }
    .rating-star{
        height: 25px;
        width: 25px;
        position: absolute;
        top: 80px;
        left: 372px;
    }

    .car-card,
    .pool-card,
    .garden-card,
    .house-card,
    .slot-card{
        width: 90%;
        height: 90px;
        margin-left: 5%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px;
        background-color: lightblue;
        position: relative;
    }
    .room-card{
        width: 90%;
        height: 50px;
        margin-left: 5%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px;
        background-color: white;
        position: relative;
    }
    .bathroom-card{
        width: 90%;
        height: 50px;
        margin-left: 5%;
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 2px;
        background-color: white;
        position: relative;
    }

    .cc-name,
    .gc-name,
    .pc-name,
    .hc-name,
    .sc-name{
        font-size: 15px;
        font-weight: bold;
        position: absolute;
        top: 5px;
        left: 10px;
    }

    .cc-fee,
    .gc-fee,
    .pc-fee,
    .hc-fee,
    .sc-fee{
        font-size: 15px;
        font-weight: bold;
        position: absolute;
        top: 5px;
        right: 10px;
    }

    .sc-period{
        position: absolute;
        font-size: 12px;
        font-weight: bold;
        bottom: 0px;
        left: 10px;
    }

    .sc-date{
        position: absolute;
        font-size: 12px;
        font-weight: bold;
        bottom: 0px;
        left: 80px;
    }

    .extra{
        color: green;
        font-size: 10px;
        font-weight: bold;
        margin: 0px;
    }
    .extras-block{
        width: 130px;
        height: 40px;
        position: absolute;
        bottom:5px;
        left: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .rc-name{
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        left: 10px;
    }
    .rc-fee{
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        right: 10px;
    }
    .room-windows{
        font-size: 10px;
        font-weight: bold;
        color: green;
        position: absolute;
        top: 20px;
        left: 10px;
    }
    .bc-name{
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        left: 10px;
    }
    .bc-fee{
        font-size: 12px;
        font-weight: bold;
        position: absolute;
        top: 0px;
        right: 10px;
    }
    .bathroom-windows{
        font-size: 10px;
        font-weight: bold;
        color: green;
        position: absolute;
        top: 20px;
        left: 10px;
    }
    .totals-block{
        width: 80%;
        height: 86%;
        margin-left:10%;
        border-bottom: solid 2px gray;
        position: relative;
    }
    .subtotal-statement{
        position: absolute;
        top: 10px;
        left: 40px;
        margin: 0px;
        font-size: 15px;
        font-weight: bold;
    }
    .fee-statement{
        position: absolute;
        top: 40px;
        left: 40px;
        margin: 0px;
        font-size: 15px;
        font-weight: bold;
    }
    .total-statement{
        position: absolute;
        top: 70px;
        left: 40px;
        margin: 0px;
        font-size: 15px;
        font-weight: bold;
    }
    .subtotal-value{
        position: absolute;
        top: 10px;
        left: 230px;
        margin: 0px;
        font-size: 15px;
    }
    .fee-value{
        position: absolute;
        top: 40px;
        left: 230px;
        margin: 0px;
        font-size: 15px;
    }
    .total-value{
        position: absolute;
        top: 70px;
        left: 230px;
        margin: 0px;
        font-size: 15px;
    }
    .bottom-section{
        display: flex;
        flex-direction: row;
        position: relative;
    }
    .bottom-section button{
        height: 50px;
        width: 140px;
        margin: 10px;
        border-radius: 4vmax;
        border: 3px solid lightblue;
        background-color: white;
        cursor: pointer;
    }
    .bottom-section button:hover{
        box-shadow: 0px 0px 8px 0px darkblue;
    }

    .top-section{
        width: fit-content;
        height: 45px;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .directions-button,
    .message-button{
        height: 45px;
        width: 100px;
        border-radius: 4vmax;
        border: solid 2px lightblue;
        background-color: white;
        cursor: pointer;
        margin-left: 10px;
        margin-right: 10px;
    }
    .directions-button:hover,
    .message-button:hover{
        box-shadow: 0px 0px 8px 0px darkblue;
    }
    .late-block{
        width: 140px;
        height: 100px;
        border-radius: 0.5vmax;
        box-shadow: 0px 0px 8px 0px black;
        background-color: rgba(173, 216, 230,0.9);
        position: absolute;
        left: 30%;
        top: 10px;
        z-index: 1;
    }

    .late-message{
        font-size: 12px;
        font-weight: bold;
        text-align: center;
    }
    .late-time{
        font-size: 15px;
        font-weight: bold;
        text-align: center;
    }
    .options{
        height: 20px;
        width: 20px;
        position: absolute;
        left: 450px;
        top: 107px;
        cursor: pointer;
    }
    .options-block{
        height: 100px;
        width: 100px;
        position: absolute;
        left: 470px;
        top: 27px;
        border-radius: 0.5vmax;
        background-color: rgba(173, 216, 230,0.6);
        z-index: 10;
    }
    .grid1{
        grid-template-rows: 1fr;
    }
    .grid2{
        grid-template-rows: 1fr 1fr;
    }
    .options-block p{
        font-size: 12px;
        font-weight: bold;
        text-align: center;
        cursor: pointer;
        margin: 0px;
        margin-left: 5%;
        box-shadow: 0px 0px 2px 0px black;
        border-radius: 0.2vmax;
        width: 90%;
        background-color: pink;
    }
    .options-block p:hover{
        color: white;
    }
    .options-block div{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .payment-type{
        margin-bottom: 5px;
        width: 105px;
        height: 40px;
        color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: center;
        background-color: rgba(12, 167, 12,0.6);
        border-radius: 4vmax;
    }

    .payment-type p{
        width: 100%;
    }

    @media(max-width: 1050px){
        .data-block{
            width: 850px;
        }
        .frame{
            height: 120px;
            width: 120px;
            margin: 20px;
        }
        .booking-name{
            left: 195px;
        }
        .booking-surname{
            left: 195px;
        }
        .booking-rating{
            left: 320px;
        }
        .booking-jobs{
            left: 320px;
        }
        .booking-statement{
            left: 175px;
        }
        .rating-star{
            left: 342px;
        }
        .options{
            left: 390px;
        }
        .options-block{
            left: 350px;
            top: 130px;
            height: 80px;
        }
    }
    @media(max-width: 900px){
        .data-block{
            width: 95%;
            height: fit-content;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .left-block{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .profile-block{
            width: 420px;
        }
        .options-block{
            left: 310px;
            z-index: 10;
        }
        .directions-button,
        .message-button{
            height: 40px;
            width: 80px;
        }
    }
    @media(max-width: 700px){
        .circle1,
        .circle2,
        .circle3,
        .circle4{
            width: 60px;
            height: 60px;
        }
    
        .connector1,
        .connector2,
        .connector3{
            height: 25px;
            width: 60px;
        }
    
        .connector1{
            left: -4px;
        }
        .connector2{
            left: -12px;
        }
        .connector3{
            left: -20px;
        }
        
        .circle2{
            left: -8px;
        }
        .circle3{
            left: -16px;
        }
        .circle4{
            left: -24px;
        }
    
        .circle-filler,
        .inner-circle{
            width: 40px;
            height: 40px;
        }
    
        .inner-circle{
            margin: 10px;
        }
    
        .connector-filler,
        .inner-connector{
            height: 5px;
            width: 80px;
        }
    
        .inner-connector{
            margin-top: 10px;
            left: -9px;
        }
    
        .inner-circle p{
            font-size: 15px;
        }
        .progress-block{
            width: 400px;
        }
        .progress-labels{
            width: 400px;
        }
    
        .label1,
        .label2,
        .label3,
        .label4{
            width: fit-content;
            font-size: 12px;
        }
        .label1{
            left: 0px;
        }
        .label2{
            left: 120px;
        }
        .label3{
            left: 240px;
        }
        .label4{
            right: 0px;
        }
        .bottom-section button{
            height: 40px;
            width: 110px;
            border-radius: 4vmax;
            border: 3px solid lightblue;
            background-color: white;
            font-size: 10px;
            font-weight: bold;
            cursor: pointer;
        }
        .directions-button,
        .message-button{
            height: 30px;
            width: 70px;
            font-size: 10px;
            font-weight: bold;
        }
    }
    @media(max-width: 450px){
        .circle1,
        .circle2,
        .circle3,
        .circle4{
            width: 40px;
            height: 40px;
        }
    
        .connector1,
        .connector2,
        .connector3{
            height: 15px;
            width: 40px;
        }
    
        .connector1{
            left: -4px;
        }
        .connector2{
            left: -12px;
        }
        .connector3{
            left: -20px;
        }
        
        .circle2{
            left: -8px;
        }
        .circle3{
            left: -16px;
        }
        .circle4{
            left: -24px;
        }
    
        .circle-filler,
        .inner-circle{
            width: 30px;
            height: 30px;
        }
    
        .inner-circle{
            margin: 5px;
        }
    
        .connector-filler,
        .inner-connector{
            height: 5px;
            width: 42px;
        }
    
        .inner-connector{
            margin-top: 5px;
            left: -1px;
        }
    
        .inner-circle p{
            font-size: 15px;
        }
        .progress-block{
            width: 250px;
        }
        .progress-labels{
            width: 260px;
        }
    
        .label1,
        .label2,
        .label3,
        .label4{
            width: fit-content;
            font-size: 9px;
        }
        .label1{
            left: 0px;
        }
        .label2{
            left: 80px;
        }
        .label3{
            left: 160px;
        }
        .label4{
            right: -10px;
        }

        .totals-block{
            display: grid;
            grid-template-columns: 1fr 1fr;
        }
        .tb-left,
        .tb-right{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .subtotal-statement,
        .fee-statement,
        .total-statement,
        .subtotal-value,
        .fee-value,
        .total-value{
            position: relative;
            top: 0px;
            left: 0px;
            font-size: 12px;
            font-weight: bold;
            margin: 0px;
            height: 33.33%;
            margin-top: 5px;
        }
        .frame{
            height: 80px;
            width: 80px;
            overflow: hidden;
            border: 3px solid lightblue;
            border-radius: 100%;
            margin: 20px;
            margin-left: 160px;
            margin-bottom: 150px;
            background-color: rgb(53, 103, 119);
            box-shadow: 0px 0px 2px 0px black;
        }
        .booking-name{
            position: absolute;
            top: 150px;
            left: 30%;
            width: 60px;
            font-size: 10px;
        }
        .booking-surname{
            position: absolute;
            top: 180px;
            left: 30%;
            width: 60px;
            font-size: 10px;
        }
        .booking-rating{
            position: absolute;
            top: 150px;
            left: 57%;
            width:fit-content;
            font-size: 10px;
            font-weight: bold;
        }
        .booking-jobs{
            position: absolute;
            top: 180px;
            left: 57%;
            width: fit-content;
            font-size: 10px;
            font-weight: bold;
        }
        .booking-statement{
            position: absolute;
            top: 115px;
            left: 0px;
            width: 100%;
            text-align: center;
            font-size: 15px;
        }
        .rating-star{
            height: 15px;
            width: 15px;
            position: absolute;
            top: 155px;
            left: 61%;
        }
        .options{
            height: 15px;
            width: 15px;
            left: 70%;
            top: 185px;
        }
        .options-block{
            height: 80px;
            width: 90px;
            position: absolute;
            left: 45%;
            top: 157px;
            border-radius: 0.5vmax;
            background-color: rgba(173, 216, 230,0.9);
        }
        .options-block p{
            font-size: 10px;
        }
        .bottom-section button{
            height: 40px;
            width: 70px;
            border-radius: 4vmax;
            border: 3px solid lightblue;
            background-color: white;
            font-size: 10px;
            font-weight: bold;
            cursor: pointer;
        }
        .cc-name,
        .gc-name,
        .pc-name,
        .hc-name,
        .sc-name{
            font-size: 11px;
        }
    
        .cc-fee,
        .gc-fee,
        .pc-fee,
        .hc-fee,
        .sc-fee{
            font-size: 11px;
        }
        .late-block{
            left: 10%;
        }
    }
    @media(max-width: 300px){
        .booking-statement{
            font-size: 12px;
        }
        .bottom-section button{
            margin: 3px;
        }
        .cc-name,
        .gc-name,
        .pc-name,
        .hc-name,
        .sc-name{
            width: 60%;
            text-overflow: ellipsis;
            word-wrap: none;
            overflow: hidden;
        }

        .circle1,
        .circle2,
        .circle3,
        .circle4{
            width: 30px;
            height: 30px;
        }
    
        .connector1,
        .connector2,
        .connector3{
            height: 15px;
            width: 15%;
        }
    
        .connector1{
            left: 6.4%;
        }
        .connector2{
            left: 4%;
        }
        .connector3{
            left: 1.5%;
        }
        
        .circle1{
            left: 8%;
        }
        .circle2{
            left: 5%;
        }
        .circle3{
            left: 3%;
        }
        .circle4{
            left: 0%;
        }
    
        .circle-filler,
        .inner-circle{
            width: 20px;
            height: 20px;
        }
    
        .inner-circle{
            margin: 5px;
        }
    
        .connector-filler,
        .inner-connector{
            height: 5px;
            width: 113.5%;
        }
    
        .inner-connector{
            margin-top: 5px;
            left: -7%;
        }
    
        .inner-circle p{
            font-size: 10px;
        }
        .progress-block{
            width: 95%;
        }
        .progress-labels{
            width: 95%;
        }
    
        .label1,
        .label2,
        .label3,
        .label4{
            width: fit-content;
            font-size: 8.5px;
            width: 20%;
        }
        .label1{
            left: 0px;
        }
        .label2{
            left: 25%;
        }
        .label3{
            left: 55%;
        }
        .label4{
            right: 0px;
        }
    }
}