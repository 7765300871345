/*
 * © 2023-2024 Nqobani Vuka Dlamini
 * All rights reserved.
 */
#newSellerPage2{
    display: grid;
    grid-template-rows: 67px 3px 1fr;
    height: 1050px;
    
    .logo-block{
        width: 100%;
        height: 100%;
        background-color: lightblue;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .logo-block img{
        width: 200px;
        height: 100%;
    }
    
    .emailUnderliner{
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    
    .euLine1{
        background-color: lightblue;
    }
    
    .euLine2{
        background-color: lightblue;
    }

    form{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
    }
    h2{
        font-size: 35px;
        text-align: center;
        width: 90%;
    }

    .profileInput{
        height: 140px;
        width: 140px;
        margin-top: 20px;
        border-radius: 100%;
        border: solid 4px lightblue;
        position: relative;
        background-color: rgb(53, 103, 119);
    }
    .profileInput img{
        object-fit: contain;
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }
    
    .isInput{
        width: 400px;
        height: 40px;
        border-radius: 100vmax;
        padding: 0px;
        margin: 5px;
        border: 2px solid;
        border-top-color: black;
        border-left-color: black;
        border-right-color: rgb(118, 118, 118);
        border-bottom-color: rgb(118, 118, 118);
        font-size: 15px;
        cursor: pointer;
    }
    
    .isInput p{
        margin: 0px;
        width: 100%;
        text-align: center;
        position: relative;
        top: 10px;
    }
    
    .fileInput{
        width: 400px;
        height: 40px;
        border-radius: 0.5vmax;
        padding: 0px;
        margin: 5px;
        border: 2px solid;
        border-top-color: black;
        border-left-color: black;
        border-right-color: rgb(118, 118, 118);
        border-bottom-color: rgb(118, 118, 118);
        font-size: 15px;
    }

    .idInput{
        width: 400px;
        height: 40px;
        border-radius: 4vmax;
        padding: 0px;
        margin: 5px;
        border: 2px solid;
        border-top-color: black;
        border-left-color: black;
        border-right-color: rgb(118, 118, 118);
        border-bottom-color: rgb(118, 118, 118);
    }
    
    .inputSection1 textarea{
        width: 400px;
        height: 200px;
        border-radius: 0.5vmax;
        padding: 0px;
        margin: 0px;
        border: 2px solid;
        border-top-color: black;
        border-left-color: black;
        border-right-color: rgb(118, 118, 118);
        border-bottom-color: rgb(118, 118, 118);
        font-size: 15px;
    }
    
    .label-holder{
        width: 430px;
        position: relative;
    }

    .s3Stack{
        display: grid;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        flex-shrink: 0;
        height: 220px;
        width: 430px;
        overflow-y: scroll;
        border-radius: 0.5vmax;
        padding: 0px;
        margin: 0px;
        border: 2px solid;
        border-top-color: black;
        border-left-color: black;
        border-right-color: rgb(118, 118, 118);
        border-bottom-color: rgb(118, 118, 118);
    }
    
    .s3Button{
        width: 300px;
        height: 40px;
        border-radius: 100vmax;
        padding:0px;
        margin: 0px;
        margin-top: 30px;
        margin-bottom: 30px;
        border: 2px solid;
        border-bottom-color: black;
        border-right-color: black;
        border-left-color: rgb(118, 118, 118);
        border-top-color: rgb(118, 118, 118);
        background-color: black;
        color: white;
    }
        
    .s3TXT{
        text-align: center;
        width: 100%;
    }
    .s3Underliner{
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 600px;
        height: 3px;
        margin: 0px;
    }
    
    .s3Underliner div{
        height: 2px;
    }
    
    .s3UL1{
        background-color: black;
    }
    
    .s3UL2{
        background-color: lightblue;
    }
    
    .reqTag{
        color: red;
        position: absolute;
        top: 0px;
        right: 0px;
    }

    .s3-popup{
        position: fixed;
        height: 150px;
        width: 130px;
        padding: 5px;
        box-shadow: 0px 0px 10px 0px darkblue;
        top: 50vh;
        left: 45vw;
        z-index: 100;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .popup-message{
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        width: 100%;
    }
    .popup-closer{
        position: absolute;
        font-weight: bold;
        top: -40px;
        right: -20px;
        cursor: pointer;
    }

    @media(max-width:650px){
        p,
        h1,
        h3,
        input,
        button{
            font-weight: bold;
        }
        .s4Underliner{
            width: 80%;
        }
       
        .isInput{
            width: 85%;
            margin-left: 7.5%;
        }
         
        .idInput,
        .fileInput{
            width: 85%;
        }
        
        .inputSection1 textarea{
            width: 80%; 
        }
        
        .s3Stack{
            width: 85%;
        }
        
        .s3Button{
            width: 200px;
        }
        .s3-popup{
            left: 35vw;
        }
        .s3Underliner{
            width: 90%;
        }
        h2{
            font-size: 25px;
        }
    }
    @media (max-width: 450px){
        .pageHeader{
            font-size: 30px;
            font-family: 'Great Vibes', cursive;
            color: white;
        }
        .s3-popup{
            left: 30vw;
        }
        h2{
            font-size: 20px;
        }
    }
}

